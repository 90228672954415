import React, { useCallback } from 'react'
import {
  IoIosPricetags,
  IoIosPeople,
  IoIosPerson,
  IoMdHome,
  IoIosInformationCircle,
  IoIosHelpCircle,
  IoIosBook
} from 'react-icons/io'
import { FaCross } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useDispatch } from 'redux-react-hook'
import styled from '@emotion/styled'

import { utils } from '../../store/constants'

const query = graphql`
  query NavigationQuery {
    pageLinks: allPrismicHeroLinksBodyPageLink {
      edges {
        node {
          primary {
            label
            link
          }
        }
      }
    }
  }
`

const Menu = styled.nav`
  @media (max-width: ${props => props.theme.breakpoints.menu}) {
    display: none;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: ${props => props.theme.framework.unit.s};

  ul {
    margin-bottom: 0;

    li {
      display: inline-block;
      list-style-type: none;
      margin-bottom: 0;

      svg {
        display: none;
      }

      a {
        &[aria-current='page'] {
          text-decoration: underline;
        }
      }

      ~ li {
        margin-left: 1rem;
      }
    }
  }
`

const Burger = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.menu}) {
    display: none;
  }

  position: relative;
  cursor: pointer;
  padding: 15px;
  margin-left: ${props => props.theme.framework.unit.s};

  span {
    display: block;
    height: 2px;
    width: 15px;
    background: ${props => (props.invert ? props.theme.colors.white : props.theme.colors.dark)};

    ~ span {
      margin-top: 3px;
    }
  }

  small {
    margin: 0;
    padding: 0.25rem 0.5rem;
    border-radius: 50px;

    color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.white};
  }
`

export const getIconFromLink = link => {
  const linkIconMap = {
    '/': IoMdHome,
    '/jesus': FaCross,
    '/topics': IoIosPricetags,
    '/testimonies': IoIosPeople,
    '/authors': IoIosPerson,
    '/articles': IoIosBook,
    '/contact': IoIosHelpCircle,
    '/who-we-are': IoIosInformationCircle
  }

  const Icon = linkIconMap[link]

  return Icon && <Icon size={25} />
}

export const printLink = (data, callback) => {
  const { link, label } = data.node.primary

  return (
    <li key={link} className="font-family-alt">
      <Link to={link} onClick={callback}>
        {getIconFromLink(link)}
        {label}
      </Link>
    </li>
  )
}

const Navigation = ({ invert }) => {
  const { pageLinks } = useStaticQuery(query)
  const dispatch = useDispatch()
  const toggleMenu = useCallback(() => dispatch({ type: utils.TOGGLE_MENU }), [dispatch])

  return (
    <>
      <Menu invert={invert}>
        <ul>{pageLinks.edges.map(data => printLink(data))}</ul>
      </Menu>

      <Burger invert={invert} onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </Burger>
    </>
  )
}

Navigation.propTypes = {
  invert: PropTypes.bool.isRequired
}

export default Navigation
