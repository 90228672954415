import React from 'react'
import PropTypes from 'prop-types'
import { createStore } from 'redux'
import { StoreContext } from 'redux-react-hook'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import { SEO } from '..'
import Footer from './Footer'
import SkipNavLink from './SkipNavLink'
import NavigationMobile from './NavigationMobile'

import { reset, global } from '../../styles'
import theme from '../../../config/theme'
import reducers from '../../store/reducers'
import '../../fragments'

import 'typeface-lora'
import 'typeface-source-sans-pro'
import '@reach/skip-nav/styles.css'

const store = createStore(reducers)

const Layout = ({ children, customSEO }) => (
  <StoreContext.Provider value={store}>
    <ThemeProvider theme={theme}>
      <>
        <Global
          styles={css`
            ${reset}
            ${global}
          `}
        />

        <SkipNavLink />
        {!customSEO && <SEO />}
        <NavigationMobile />
        {children}

        <Footer />
      </>
    </ThemeProvider>
  </StoreContext.Provider>
)

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customSEO: PropTypes.bool
}

Layout.defaultProps = {
  customSEO: false
}

export default Layout
