const entity = {
  urls: {
    index: '/articles',
    link: document => `/article/${document.uid}`
  },
  labels: {
    singular: 'artikel',
    singularAlt: 'Artikel',
    plural: 'artikelen',
    pluralAlt: 'Artikelen'
  }
}

exports.entity = entity

exports.getHeadline = meta => ({
  meta,
  link: {
    text: `alle ${entity.labels.plural}`,
    url: entity.urls.index
  }
})

exports.create = async (graphql, createPage, wrapper) => {
  createPage({
    path: entity.urls.index,
    component: require.resolve('./page.jsx')
  })

  const result = await wrapper(
    graphql(`
      {
        allPrismicArticle {
          edges {
            node {
              uid
            }
          }
        }
      }
    `)
  )

  result.data.allPrismicArticle.edges.forEach(edge => {
    createPage({
      path: entity.urls.link(edge.node),
      component: require.resolve('./template.jsx'),
      context: {
        // Pass the unique ID (uid) through context so the template can filter by it
        uid: edge.node.uid
      }
    })
  })

  return result.data.allPrismicArticle.edges
}
