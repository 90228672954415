import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Topics from './Topics'

const Item = styled.li``

const Headline = styled.p`
  color: ${props => props.theme.colors.grey};
  margin-bottom: 0;

  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
  }
`

const StyledLink = styled(Link)`
  font-size: 175%;
  color: ${props => props.theme.colors.black};
  font-style: normal;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 125%;
  }
`

const ListItem = ({ extra, label, date, link, topics }) => (
  <Item>
    <Headline className='font-family-alt'>
      {date} {date && extra && '—'} {extra}
      {topics && <Topics topics={topics} />}
    </Headline>

    <StyledLink to={link}>{label}</StyledLink>
  </Item>
)

export default ListItem

ListItem.propTypes = {
  date: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({}))
}

ListItem.defaultProps = {
  date: null,
  extra: null,
  topics: []
}
