import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image/withIEPolyfill'

const Content = styled.div`
  max-width: ${props => props.theme.framework.maxWidth};

  img {
    margin: 0;
  }
`

const Image = ({ data }) => (
  <Content className="zone-slice">
    <Img fluid={data.image.localFile.childImageSharp.fluid} />
  </Content>
)

export default Image

Image.propTypes = {
  data: PropTypes.shape({}).isRequired
}
