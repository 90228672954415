module.exports = {
  colors: {
    primary: '#005b96',
    black: '#131313',
    dark: '#303643',
    grey: '#595C62',
    light: '#EBEDF2',
    white: '#fdfdfd'
  },
  framework: {
    maxWidth: '1000px',
    unit: {
      default: '1.5rem',
      content: '1rem',
      s: '2rem'
    }
  },
  breakpoints: {
    menu: '900px',
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px'
  }
}
