import styled from '@emotion/styled'

const StyledCard = styled.div`
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.1);
  border: 1px solid ${props => props.theme.colors.light};
  padding: ${props => props.theme.framework.unit.default};

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    padding: ${props => props.theme.framework.unit.s};
  }
`

export default StyledCard
