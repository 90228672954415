import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { entity } from '.'
import { entity as authorEntity } from '../author'
import { Row, Column, Layout, Listing, Wrapper, SEO, Hero } from '../../components'
import { framework } from '../../../config/theme'
import website from '../../../config/website'

const Main = Wrapper.withComponent('main')

const printTestimonies = (testimonies, extern) => (
  <Listing
    pages={10} // Enable pager
    title={`${extern ? 'Externe' : 'Interne'} ${entity.labels.plural}`}
    edges={testimonies.edges}
    link={edge => entity.urls.link(edge.node)}
    date={edge => edge.node.data.date}
    extra={edge =>
      edge.node.data.author ? (
        <Link to={authorEntity.urls.link(edge.node.data.author)}>{edge.node.data.author.document[0].data.name}</Link>
      ) : null
    }
  />
)

const Testimonies = ({ data: { internalTestimonies, externalTestimonies }, location }) => (
  <Layout customSEO>
    <SEO title={`${entity.labels.pluralAlt} | ${website.titleShort}`} pathname={location.pathname} />
    <Hero title={`Alle ${entity.labels.plural}`} invert />
    <Main id={website.skipNavId}>
      <Row separateColumns={{ default: framework.unit.default, bp: { s: framework.unit.s } }}>
        {internalTestimonies.edges.length > 0 && <Column>{printTestimonies(internalTestimonies, false)}</Column>}
        {externalTestimonies.edges.length > 0 && <Column>{printTestimonies(externalTestimonies, true)}</Column>}
      </Row>
    </Main>
  </Layout>
)

export default Testimonies

Testimonies.propTypes = {
  data: PropTypes.shape({
    internalTestimonies: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
    externalTestimonies: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
  }),
  location: PropTypes.shape({}).isRequired,
}

Testimonies.defaultProps = {
  data: {
    internalTestimonies: {
      edges: [],
      length: 0,
    },
    externalTestimonies: {
      edges: [],
      length: 0,
    },
  },
}

export const pageQuery = graphql`
  query TestimoniesPage {
    internalTestimonies: allPrismicTestimony(
      limit: 5
      sort: { fields: last_publication_date, order: DESC }
      filter: { data: { external: { ne: "Yes" }, enabled: { eq: "Yes" } } }
    ) {
      ...Testimonies
    }
    externalTestimonies: allPrismicTestimony(
      limit: 5
      sort: { fields: last_publication_date, order: DESC }
      filter: { data: { external: { eq: "Yes" }, enabled: { eq: "Yes" } } }
    ) {
      ...Testimonies
    }
  }
`
