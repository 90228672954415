import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Row, Column } from '..'
import { framework } from '../../../config/theme'

const query = graphql`
  query FooterQuery {
    footer: prismicFooter {
      row: data {
        columns: column {
          title: column_title
          content: column_content {
            html
          }
        }
      }
    }
    site {
      siteMetadata {
        siteTitle: title
      }
    }
  }
`

const StyledTitle = styled.p`
  color: ${props => props.theme.colors.black};
  font-style: italic;
  font-size: 150%;
  margin-bottom: 0.5rem;
`

const StyledContent = styled.div`
  ul,
  ol {
    margin: 0;

    li {
      list-style: none;
      margin: 0;

      + li {
        margin-top: 0.25rem;
      }
    }
  }

  p {
    margin: 0;

    + p {
      margin-top: 0.5rem;
    }
  }

  iframe {
    margin-top: 1rem;
    height: auto;
  }
`

const StyledFooter = styled.footer`
  color: ${props => props.theme.colors.grey};
  background: ${props => props.theme.colors.light};
  font-size: 75%;
  opacity: 0.75;
`

const StyledInnerFooter = styled.div`
  max-width: ${props => props.theme.framework.maxWidth};
  margin: 0 auto;
  padding: ${props => props.theme.framework.unit.default};

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    padding: ${props => props.theme.framework.unit.s};
  }
`

const StyledCopyright = styled.div`
  background: ${props => props.theme.colors.light};
  font-size: 75%;

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    font-size: 85%;
  }
`

const StyledInnerCopyright = styled.div`
  max-width: ${props => props.theme.framework.maxWidth};
  margin: 0 auto;
  padding: 0.5rem ${props => props.theme.framework.unit.default};

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    padding: 0.5rem ${props => props.theme.framework.unit.s};
  }
`

const Footer = () => {
  const {
    footer: {
      row: { columns }
    },
    site: {
      siteMetadata: { siteTitle }
    }
  } = useStaticQuery(query)

  return (
    <StyledFooter>
      <StyledInnerFooter>
        <Row separateColumns={{ default: framework.unit.default, bp: { s: framework.unit.s } }}>
          {columns.map(({ title, content: { html } }) => (
            <Column key={title}>
              <StyledTitle>{title}</StyledTitle>
              <StyledContent dangerouslySetInnerHTML={{ __html: html }} />
            </Column>
          ))}
        </Row>
      </StyledInnerFooter>

      <StyledCopyright>
        <StyledInnerCopyright>
          Copyright © {new Date().getFullYear()} | {siteTitle}
        </StyledInnerCopyright>
      </StyledCopyright>
    </StyledFooter>
  )
}

export default Footer
