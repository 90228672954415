import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: ${props => props.padding || 0};

  > div {
    + div {
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-top: ${props => (props.separateColumns && props.separateColumns.default) || 0};
      }

      ${props =>
        props.separateColumns &&
        props.separateColumns.bp &&
        Object.entries(props.separateColumns.bp).map(
          ([point, amount]) => css`
            @media (min-width: ${props.theme.breakpoints[point]}) {
              margin-left: ${amount};
            }
          `
        )};
    }
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: ${props => (props.padding && props.padding.default) || 0};

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    flex: 1;
  }

  ${props =>
    props.padding &&
    props.padding.bp &&
    Object.entries(props.padding.bp).map(
      ([point, amount]) => css`
        @media (min-width: ${props.theme.breakpoints[point]}) {
          padding: ${amount};
        }
      `
    )};
`

export default { Row, Column }
