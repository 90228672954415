import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Layout, Wrapper, SEO, Hero } from '../../components'
import BlockQuote from '../../components/BlockQuote'
import website from '../../../config/website'

const Main = Wrapper.withComponent('main')

const Quote = styled(BlockQuote)`
  margin-top: 1rem;
`

const Node = ({ data: { node }, location }) => (
  <Layout customSEO>
    <SEO title={`${node.data.title.text} | ${website.titleShort}`} pathname={location.pathname} />
    <Hero title={node.data.title.text} />
    <Main id={website.skipNavId}>
      <div dangerouslySetInnerHTML={{ __html: node.data.content.html }} />

      {node.data.bible_verses.map(({ quote }, i) => (
        <Quote key={i} dangerouslySetInnerHTML={{ __html: quote.html }} />
      ))}
    </Main>
  </Layout>
)

export default Node

Node.propTypes = {
  pageContext: PropTypes.shape({
    uid: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    node: PropTypes.shape({
      data: PropTypes.shape({})
    }).isRequired
  }),
  location: PropTypes.shape({}).isRequired
}

export const pageQuery = graphql`
  query NodeTemplateQuery($uid: String!) {
    node: prismicNode(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        content {
          html
        }
        bible_verses {
          quote {
            html
          }
        }
      }
    }
  }
`
