const entity = {
  urls: {
    index: '/nodes',
    link: document => `/node/${document.uid}`
  },
  labels: {
    singular: 'node',
    singularAlt: 'Node',
    plural: 'nodes',
    pluralAlt: 'Nodes'
  }
}

exports.entity = entity

exports.getHeadline = () => ({
  meta: entity.labels.singularAlt,
  link: {
    text: `alle ${entity.labels.plural}`,
    url: entity.urls.index
  }
})

exports.create = async (graphql, createPage, wrapper) => {
  createPage({
    path: entity.urls.index,
    component: require.resolve('./page.jsx')
  })

  const result = await wrapper(
    graphql(`
      {
        allPrismicNode {
          edges {
            node {
              uid
              data {
                title {
                  text
                }
                content {
                  text
                }
              }
            }
          }
        }
      }
    `)
  )

  result.data.allPrismicNode.edges.forEach(edge => {
    createPage({
      path: entity.urls.link(edge.node),
      component: require.resolve('./template.jsx'),
      context: {
        uid: edge.node.uid
      }
    })
  })

  return result.data.allPrismicNode.edges
}
