import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Author from '../../entities/author'
import { Carousel } from '.'

export default () => (
  <StaticQuery
    query={graphql`
      query AuthorsCarousel {
        authors: allPrismicAuthor {
          ...Authors
        }
      }
    `}
    render={data => {
      const carouselData = data.authors.edges
        .map(edge => edge.node)
        .map(node => ({
          text: node.data.name,
          img: node.data.profile_picture.url,
          title: Author.entity.labels.singularAlt,
          link: `/author/${node.uid}`
        }))

      return <Carousel data={carouselData} />
    }}
  />
)
