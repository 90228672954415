import React from 'react'
import PropTypes from 'prop-types'
import BlockQuote from '../../BlockQuote'

const Quote = ({ data }) => <BlockQuote className="zone-slice" dangerouslySetInnerHTML={{ __html: data.quote.html }} />

export default Quote

Quote.propTypes = {
  data: PropTypes.shape({}).isRequired
}
