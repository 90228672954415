// https://www.gatsbyjs.org/docs/add-seo-component/

import { get } from 'lodash'
import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { trimTrailingSlash } from '../../utils'

const query = graphql`
  query SchemaQuery {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultLogo: logo
        defaultAuthor: author
        siteUrl
        headline
        siteLanguage
      }
    }
  }
`

const Schema = ({ seo, article, node }) => {
  const {
    site: {
      buildTime,
      siteMetadata: { siteUrl, defaultTitle, defaultDescription, defaultLogo, defaultAuthor, headline, siteLanguage }
    }
  } = useStaticQuery(query)

  const siteUrlTrimmed = trimTrailingSlash(siteUrl)
  const articleAuthor = get(node, 'data.author.document[0].data.name', defaultAuthor)

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const defaultSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    headline,
    url: siteUrlTrimmed,
    name: defaultTitle,
    description: defaultDescription,
    mainEntityOfPage: siteUrlTrimmed,
    inLanguage: siteLanguage,
    datePublished: new Date(),
    dateModified: buildTime,
    author: { '@type': 'Person', name: defaultAuthor },
    creator: { '@type': 'Person', name: defaultAuthor },
    publisher: { '@type': 'Person', name: defaultAuthor },
    copyrightHolder: { '@type': 'Person', name: defaultAuthor },
    copyrightYear: new Date().getFullYear(),
    image: { '@type': 'ImageObject', url: `${siteUrlTrimmed}/${defaultLogo}` }
  }

  const breadcrumbs = [{ '@type': 'ListItem', item: { '@id': siteUrlTrimmed, name: 'Homepage' }, position: 1 }]

  let schema = defaultSchema

  if (article) {
    schema = {
      ...defaultSchema,
      '@type': 'Article',
      url: seo.url,
      name: seo.title,
      description: seo.description,
      mainEntityOfPage: seo.url,
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      // author: { '@type': 'Person', name: articleAuthor },
      // creator: { '@type': 'Person', name: articleAuthor },
      // copyrightHolder: { '@type': 'Person', name: articleAuthor },
      image: { '@type': 'ImageObject', url: seo.image },
      publisher: {
        '@type': 'Organization',
        name: defaultTitle,
        logo: { '@type': 'ImageObject', url: `${siteUrlTrimmed}/${defaultLogo}` }
      }
    }

    breadcrumbs.push({ '@type': 'ListItem', item: { '@id': seo.url, name: seo.title }, position: 2 })
  }

  return (
    <Helmet>
      {/* Insert schema.org data */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>

      {/* Insert breadcrumbs */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          description: 'Breadcrumbs list',
          name: 'Breadcrumbs',
          itemListElement: breadcrumbs
        })}
      </script>
    </Helmet>
  )
}

export default Schema

Schema.propTypes = {
  article: PropTypes.bool,
  seo: PropTypes.shape({}),
  node: PropTypes.shape({})
}

Schema.defaultProps = {
  seo: {},
  article: false,
  node: null
}
