import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Navigation from './Navigation'
import Logo from '../../../static/logos/logo.png'

const query = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
  }
`

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.framework.unit.default};
  width: 100%;

  a {
    color: ${props => (props.invert ? props.theme.colors.white : props.theme.colors.dark)};
    font-weight: normal;
    font-style: normal;
  }

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: ${props => props.theme.framework.unit.s};
  }
`

const StyledLogo = styled.img`
  width: 50px;
  margin-right: 0.5rem;
`

const StyledTitle = styled.span`
  @media (max-width: 395px) {
    display: none;
  }
`

const Header = ({ invert }) => {
  const {
    site: {
      siteMetadata: { siteTitle }
    }
  } = useStaticQuery(query)

  return (
    <StyledHeader invert={invert}>
      <Link to="/" aria-label="Terug" className="font-family-alt">
        <StyledLogo src={Logo} alt="Logo" />
        <StyledTitle>{siteTitle}</StyledTitle>
      </Link>

      <Navigation invert={invert} />
    </StyledHeader>
  )
}

export default Header

Header.propTypes = {
  invert: PropTypes.bool.isRequired
}
