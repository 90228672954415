import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Carousel } from '.'

export default () => (
  <StaticQuery
    query={graphql`
      query BibleQuotesCarousel {
        allPrismicBibleQuotes(filter: { data: { belongs_to: { uid: { eq: "jesus" } } } }) {
          nodes {
            data {
              quote {
                title
                text {
                  text
                }
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      const quotes = []
        .concat(...queryData.allPrismicBibleQuotes.nodes.map(node => node.data.quote))
        .map(({ title, text }) => ({ title, text: text.text }))

      return <Carousel single config={{ dots: false }} data={quotes} />
    }}
  />
)
