import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Wrapper = styled.div`
  max-width: ${props => props.theme.framework.maxWidth};
  padding: ${props => props.theme.framework.unit.default};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    padding: ${props => props.theme.framework.unit.s};
  }

  ${props =>
    props.zones &&
    css`
      .zone + .zone {
        margin-top: ${props.theme.framework.unit.default};

        @media (min-width: ${props.theme.breakpoints.s}) {
          margin-top: ${props.theme.framework.unit.s};
        }
      }
    `};
`

export default Wrapper
