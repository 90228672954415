import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Hero from './index'

const HeroInner = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.s}) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const HeroTitle = styled.h1`
  word-break: break-word;
  font-size: 300%;
  text-align: center;
  margin: 0;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    @media (orientation: landscape) {
      font-size: 200%;
    }
  }
`

const HeroHeadline = styled.div`
  font-size: 175%;
  text-align: center;

  p {
    margin: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 150%;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 100%;

    @media (orientation: portrait) {
      display: none;
    }
  }
`

const FeaturedHero = ({ image, invert, title, headline, properties }) => (
  <Hero image={image} invert={invert} properties={properties} featured>
    <HeroInner>
      <HeroTitle>{title}</HeroTitle>
      {headline && <HeroHeadline dangerouslySetInnerHTML={{ __html: headline }} />}
    </HeroInner>
  </Hero>
)

FeaturedHero.propTypes = {
  image: PropTypes.shape({
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({})
      }).isRequired
    }).isRequired
  }),
  properties: PropTypes.shape({}),
  invert: PropTypes.bool,
  title: PropTypes.string.isRequired,
  headline: PropTypes.string
}

FeaturedHero.defaultProps = {
  invert: false,
  properties: null,
  headline: null,
  image: {
    localFile: {
      childImageSharp: {
        fluid: null
      }
    }
  }
}

export default FeaturedHero
