import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ListItem from './ListItem'
import { Title } from '..'

const List = styled.ul`
  list-style-type: none;
  margin: 0;

  li {
    margin: 0;

    ~ li {
      margin-top: ${props => props.theme.framework.unit.content};
    }
  }
`

const StyledListing = styled.div``

const StyledPager = styled.div`
  display: inline-block;
  font-size: 65%;
  background: ${props => props.theme.colors.light};
  padding: 5px ${props => props.theme.framework.unit.content};
  margin-bottom: ${props => props.theme.framework.unit.content};

  span {
    + span {
      margin-left: 15px;
    }
  }
`

const StyledPagerInfo = styled.em``

const StyledPagerAction = styled.span`
  display: inline-block;
  cursor: pointer;
  color: ${props => (props.disabled ? props.theme.colors.light : props.theme.colors.dark)};
  text-decoration: underline;
`

const Listing = ({ edges, link, date, extra, label, title, className, pages }) => {
  const [page, setPage] = useState(1)
  const enablePager = pages > 0
  const maxPages = enablePager ? Math.ceil(edges.length / pages) : 1
  const slicedEdges = enablePager ? edges.slice((page - 1) * pages, page * pages) : edges

  return (
    <StyledListing className={className}>
      {title && <Title>{title}</Title>}

      {enablePager && (
        <StyledPager>
          <StyledPagerInfo>
            Pagina {page}/{maxPages}
            {maxPages > 1 && <span>&nbsp; — &nbsp;</span>}
          </StyledPagerInfo>

          {page > 1 && (
            <StyledPagerAction onClick={() => setPage(page > 1 ? page - 1 : 1)}>vorige {pages}</StyledPagerAction>
          )}

          {page < maxPages && (
            <StyledPagerAction onClick={() => setPage(page < maxPages ? page + 1 : maxPages)}>
              volgende {pages}
            </StyledPagerAction>
          )}
        </StyledPager>
      )}

      <List>
        {slicedEdges.map(edge => {
          const id = link(edge)
          const href = id.startsWith('/') ? id : `/${id}`

          return (
            <ListItem
              key={id}
              link={href}
              extra={extra(edge)}
              date={date(edge)}
              label={label(edge)}
              topics={edge.node.data.topics}
            />
          )
        })}
      </List>
    </StyledListing>
  )
}

export default Listing

Listing.propTypes = {
  link: PropTypes.func,
  date: PropTypes.func,
  label: PropTypes.func,
  extra: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  pages: PropTypes.number,
  edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

Listing.defaultProps = {
  pages: 0,
  title: null,
  className: null,
  extra: () => null,
  link: edge => edge.node.uid,
  date: edge => edge.node.data.date,
  label: edge => edge.node.data.title.text
}
