const { colors } = require('./theme')

const general = {
  pathPrefix: '/',
  title: 'Power Through Love',
  titleShort: 'Power Through Love',
  description: 'Om Jezus te kennen, en de kracht van zijn opstanding',
  url: 'https://www.powerthroughlove.be/',
  logo: 'logos/logo.png',
  icon: 'static/logos/logo.jpg',
  author: 'Power Through Love',
  siteLanguage: 'nl',
  ogLanguage: 'nl_BE',
}

module.exports = {
  // General
  ...general,

  // Manifest
  shortName: general.titleShort,
  themeColor: colors.primary,
  backgroundColor: colors.light,

  // Social
  twitter: '',
  facebook: '',

  // Others
  skipNavId: 'reach-skip-nav',
}
