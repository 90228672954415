import { utils } from '../constants'

const defaultState = {
  menu: {
    opened: false
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case utils.TOGGLE_MENU:
      return { ...state, menu: { ...state.menu, opened: !state.menu.opened } }
    default:
      return state
  }
}
