import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Slick from 'react-slick'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { breakpoints } from '../../../config/theme'
import { Wrapper } from '..'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const StyledCarouselWrapper = styled.div`
  background: linear-gradient(to bottom, ${props => props.theme.colors.white}, ${props => props.theme.colors.light});
`

const StyledWrapper = styled(Wrapper)`
  && {
    padding-top: 0;
    padding-bottom: 0;

    ${props =>
      props.fullWidth &&
      css`
        max-width: 100%;
        padding: 0;
      `}
  }
`

const StyledCarousel = styled(Slick)`
  ${props =>
    props.centerMode &&
    css`
      .slick-slide {
        float: none;
        display: inline-block;
        vertical-align: middle;
      }
    `}
`

const StyledSlideLinked = styled(Link)`
  padding: ${props => props.theme.framework.unit.content};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    > img {
      display: none;
    }
  }
`

const StyledSlide = styled.div`
  padding: ${props => props.theme.framework.unit.content};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    > img {
      display: none;
    }
  }
`

const StyledSlideContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledSlideTitle = styled.small`
  text-transform: uppercase;
  text-align: ${props => (props.single ? 'center' : 'left')};
  font-size: ${props => (props.single ? '75%' : '50%')};
  font-weight: ${props => (props.single ? '700' : 'normal')};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
  }
`

const StyledSlideText = styled.p`
  text-align: ${props => (props.single ? 'center' : 'left')};
  font-size: 85%;
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
  }
`

const StyledSlideImg = styled.img`
  border-radius: 50px;
  width: 35px;
  height: 35px;
  float: left;
  margin-right: ${props => props.theme.framework.unit.content};
`

const defaultCarouselConfig = {
  className: 'slider',

  // Settings
  accessibility: true,
  lazyLoad: false,
  draggable: false,
  arrows: false,
  dots: true,
  infinite: true,
  autoplay: true,
  pauseOnHover: false,
  autoplaySpeed: 5000,
  speed: 500,
  initialSlide: 0,
  centerMode: false,
  centerPadding: '30px',

  // Row mode
  rows: 1,
  slidesPerRow: 1,

  // Custom config (will be removed automatically)
  slides: {
    default: 4,
    m: 3
  }
}

const getCarouselBreakpoints = slidesConfig =>
  // Fix small carousels to only show 1 slide
  Object.entries({ s: 1, ...slidesConfig })
    .filter(([bp]) => bp !== 'default')
    .map(([bp, slides]) => {
      const stack = bp === 's' && slides === 1
      const slidesToShow = stack ? 1 : slides

      return {
        breakpoint: parseInt(breakpoints[bp].replace('px', ''), 10),
        settings: {
          dots: bp !== 's',
          slidesToShow,
          slidesToScroll: slidesToShow
        }
      }
    })

const Carousel = ({ data, fullWidth, config, single }) => {
  let carouselConfig = {
    ...defaultCarouselConfig,
    ...config
  }

  if (single) {
    carouselConfig = {
      ...carouselConfig,
      fade: true,
      centerMode: true
    }
  } else {
    const slidesToShow =
      (data.length <= defaultCarouselConfig.slides.default && data.length) ||
      (config.slides && config.slides.default) ||
      defaultCarouselConfig.slides.default

    carouselConfig = {
      ...carouselConfig,
      slidesToShow,
      slidesToScroll: slidesToShow,
      responsive: getCarouselBreakpoints(config.slides || defaultCarouselConfig.slides)
    }
  }

  delete carouselConfig.slides

  return (
    <StyledCarouselWrapper>
      <StyledWrapper fullWidth={fullWidth}>
        <StyledCarousel {...carouselConfig}>
          {data.map(({ title, link, img, text }) => {
            const Wrapper = link ? StyledSlideLinked : StyledSlide

            return (
              <Wrapper className="wrapper" key={text} to={link} single={single.toString()}>
                {img && <StyledSlideImg src={img} />}
                <StyledSlideContent>
                  <StyledSlideTitle single={single} className="font-family-alt">
                    {title}
                  </StyledSlideTitle>
                  <StyledSlideText single={single} className="font-family-main">
                    {text}
                  </StyledSlideText>
                </StyledSlideContent>
              </Wrapper>
            )
          })}
        </StyledCarousel>
      </StyledWrapper>
    </StyledCarouselWrapper>
  )
}

Carousel.propTypes = {
  config: PropTypes.shape({}),
  single: PropTypes.bool,
  fullWidth: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      img: PropTypes.string,
      text: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
}

Carousel.defaultProps = {
  config: defaultCarouselConfig,
  single: false,
  fullWidth: false
}

export default Carousel
