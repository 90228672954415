import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

const Meta = ({ title, desc, image, lang }) => (
  <Helmet title={title}>
    <html lang={lang} />
    <meta name='description' content={desc} />
    <meta name='image' content={image} />
  </Helmet>
)

export default Meta

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
}
