import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { entity } from '../../entities/author'
import StyledCard from '../Card'

const AuthorsWrapper = styled.div`
  max-width: ${props => props.theme.framework.maxWidth};
`

const StyledAuthor = styled(StyledCard)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledAuthorSection = styled.div`
  a {
    display: block;
  }

  img {
    max-width: 100px;
    height: 100px;
    border-radius: 250px;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    img {
      display: none;
    }
  }

  + div {
    @media (min-width: ${props => props.theme.breakpoints.s}) {
      margin-left: ${props => props.theme.framework.unit.s};
      padding-left: ${props => props.border && props.theme.framework.unit.s};
      border-left: ${props => props.border && `1px solid ${props.theme.colors.light}`};
    }
  }
`

const StyledAuthorLabel = styled.small`
  a {
    display: inline-block;
  }
`

const StyledAuthorTitle = styled.h2`
  margin: 0;
`

const AuthorZone = ({ uid, document }) => (
  <AuthorsWrapper className="zone">
    <StyledAuthor>
      {document[0].data.profile_picture.url && (
        <StyledAuthorSection>
          <img src={document[0].data.profile_picture.url} alt={document[0].data.name} />
        </StyledAuthorSection>
      )}

      <StyledAuthorSection border>
        <StyledAuthorLabel>
          {entity.labels.singularAlt} — <Link to={entity.urls.link({ uid })}>meer over mij</Link>
        </StyledAuthorLabel>

        <StyledAuthorTitle>{document[0].data.name}</StyledAuthorTitle>
      </StyledAuthorSection>
    </StyledAuthor>
  </AuthorsWrapper>
)

export default AuthorZone

AuthorZone.propTypes = {
  uid: PropTypes.string.isRequired,
  document: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        profile_picture: PropTypes.shape({
          url: PropTypes.string
        })
      }).isRequired
    }).isRequired
  ).isRequired
}
