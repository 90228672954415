import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const StyledTitle = styled.h2`
  font-size: 150%;
`

const StyledSection = styled.section`
  position: relative;

  + section {
    margin-top: ${props => props.theme.framework.unit.default};
    padding-top: ${props => props.theme.framework.unit.default};
    border-top: 1px solid ${props => props.theme.colors.light};
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    background-image: url("${props => props.image.localFile && props.image.localFile.childImageSharp.fluid.src}");
    background-repeat: no-repeat;
    background-size: 65%;
    background-position-y: bottom;
    background-color: #F4F4F4;
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.1);
    border: 1px solid ${props => props.theme.colors.light};
    padding: ${props => props.theme.framework.unit.s};
    filter: contrast(125%);
    opacity: 0.5;

    &.text-left-image-right {
      background-position-x: right;
      text-align: left;
      padding-right: 55%;
    }

    &.text-right-image-left {
      background-position-x: left;
      text-align: right;
      padding-left: 55%;
    }
  }
`

const StyledButton = styled(Link)`
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  border-bottom-width: 3px;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
`

const Section = ({ url, title, image, html, className }) => (
  <StyledSection image={image} className={className}>
    {url && (
      <Link to={url}>
        <StyledTitle>{title}</StyledTitle>
      </Link>
    )}

    {!url && <StyledTitle>{title}</StyledTitle>}

    <article>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      {url && <StyledButton to={url}>Lees meer</StyledButton>}
    </article>
  </StyledSection>
)

export default Section

Section.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  image: PropTypes.shape({}).isRequired
}

Section.defaultProps = {
  url: null,
  className: ''
}
