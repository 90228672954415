import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Content = styled.div`
  max-width: ${props => props.theme.framework.maxWidth};

  ul,
  ol {
    li {
      font-size: 85%;

      ~ li {
        margin: 0px;
      }
    }
  }

  ol li {
    list-style-type: upper-roman;
  }
`

const BodyText = ({ data }) => <Content className="zone-slice" dangerouslySetInnerHTML={{ __html: data.text.html }} />

export default BodyText

BodyText.propTypes = {
  data: PropTypes.shape({}).isRequired
}
