import { css } from '@emotion/core'
import theme from '../../config/theme'

const global = css`
  html {
    scroll-behavior: smooth;

    &.mobile-menu-open {
      position: fixed;
      overflow: hidden;
      width: 100%;

      body {
        overflow: hidden;
      }
    }
  }

  body {
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
  }

  ::selection {
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.black};
    margin-bottom: 0.5rem;
    word-break: break-word;
    white-space: pre-wrap;
    hyphens: auto;
  }

  a {
    display: inline-block;
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    max-width: 100%;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  iframe {
    max-width: 100%;
    margin-bottom: 0;

    @media (max-width: ${theme.breakpoints.s}) {
      height: auto;
    }
  }

  img {
    margin-bottom: 0;
  }
`

export default global
