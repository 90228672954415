import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { entity } from '.'
import { Layout, Listing, Wrapper, SEO, Hero } from '../../components'
import website from '../../../config/website'

const Main = Wrapper.withComponent('main')

const Nodes = ({
  data: {
    nodes: { totalCount, edges }
  },
  location
}) => (
  <Layout customSEO>
    <SEO title={`${entity.labels.pluralAlt} | ${website.titleShort}`} pathname={location.pathname} />
    <Hero title={`Alle ${entity.labels.plural}`} invert />
    <Main id={website.skipNavId}>
      <Listing
        pages={5}
        title={`${totalCount} ${totalCount === 1 ? entity.labels.singular : entity.labels.plural}`}
        edges={edges}
        link={edge => entity.urls.link(edge.node)}
        label={edge => edge.node.data.title.text}
      />
    </Main>
  </Layout>
)

export default Nodes

Nodes.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({}).isRequired
}

export const pageQuery = graphql`
  query Nodes {
    nodes: allPrismicNode {
      totalCount
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
          }
        }
      }
    }
  }
`
