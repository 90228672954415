import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Topic from '../../entities/topic'

const Topics = ({ topics }) => {
  const listTopics =
    (topics &&
      topics.length &&
      topics[0].topic &&
      topics.map(c => ({
        uid: c.topic.document[0].uid,
        name: c.topic.document[0].data.name
      }))) ||
    []

  return (
    listTopics.length > 0 && (
      <>
        &nbsp;—&nbsp;
        {listTopics.map((t, i) => (
          <React.Fragment key={t.uid}>
            {!!i && ', '}
            <Link to={Topic.entity.urls.link(t)}>{t.name}</Link>
          </React.Fragment>
        ))}
      </>
    )
  )
}

export default Topics

Topics.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({}))
}

Topics.defaultProps = {
  topics: []
}
