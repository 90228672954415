import styled from '@emotion/styled'

const Title = styled.p`
  font-style: italic;
  font-size: 150%;
  position: relative;
  margin-bottom: 0.5rem;

  &:before {
    content: '';
    width: 1.5rem;
    height: 1px;
    background-color: ${props => props.theme.colors.grey};
    display: inline-block;
    position: absolute;
    top: 1rem;
    left: -35px;
  }
`

export default Title
