import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getHeadline } from '.'
import Article from '../article'
import { Layout, Listing, Wrapper, SEO, Hero } from '../../components'
import website from '../../../config/website'

const Main = Wrapper.withComponent('main')

const Topic = ({
  pageContext: { topic },
  data: {
    articles: { edges, totalCount }
  },
  location
}) => (
  <Layout customSEO>
    <SEO title={`${topic} | ${website.titleShort}`} pathname={location.pathname} />
    <Hero title={topic} headline={getHeadline()} />
    <Main id={website.skipNavId}>
      <Listing
        title={`${totalCount} ${totalCount === 1 ? Article.entity.labels.singular : Article.entity.labels.plural} ${
          totalCount === 1 ? 'behoort tot' : 'behoren tot'
        } "${topic}"`}
        edges={edges}
        link={edge => Article.entity.urls.link(edge.node)}
      />
    </Main>
  </Layout>
)

export default Topic

Topic.propTypes = {
  pageContext: PropTypes.shape({
    topic: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    articles: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({}).isRequired
}

export const pageQuery = graphql`
  query TopicTemplateQuery($uid: String!) {
    articles: allPrismicArticle(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          topics: { elemMatch: { topic: { document: { elemMatch: { uid: { eq: $uid } } } } } }
          enabled: { eq: "Yes" }
        }
      }
    ) {
      ...Articles
    }
  }
`
