import { get } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import BodyText from './BodyText'
import Image from './Image'
import Quote from './Quote'
import Node from './Node'
import { Title } from '../..'

const Zone = styled.div``

const Content = styled.div`
  p,
  li {
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    margin-bottom: 0;
  }

  ul a {
    vertical-align: text-top;
  }

  * + *:not(img) {
    margin-top: ${props => props.theme.framework.unit.content};
  }

  a + a,
  a + * + a {
    margin-top: 0;
  }

  h2,
  h3 {
    margin-top: 3rem;

    + p {
      margin-top: 0;
    }
  }

  h2 + h3 {
    margin-top: 0;
  }

  blockquote + blockquote {
    margin-top: ${props => props.theme.framework.unit.content};
  }
`

const SliceZone = ({ title, allSlices }) => {
  const slice = allSlices.map(s => {
    switch (s.slice_type) {
      // These are the API IDs of the slices
      case 'text':
        return <BodyText key={s.id} data={s.primary} />
      case 'image':
        return <Image key={s.id} data={s.primary} />
      case 'quote':
        return <Quote key={s.id} data={s.primary} />
      case 'node':
        return <Node key={s.id} data={get(s, 'primary.node.document[0].data')} />
      default:
        return null
    }
  })

  return (
    <Zone className="zone">
      {title && <Title>{title}</Title>}
      <Content>{slice}</Content>
    </Zone>
  )
}

export default SliceZone

SliceZone.propTypes = {
  title: PropTypes.string,
  allSlices: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

SliceZone.defaultProps = {
  title: null
}
