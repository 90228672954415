const entity = {
  urls: {
    index: '/topics',
    link: document => `/topic/${document.uid}`
  },
  labels: {
    singular: 'onderwerp',
    singularAlt: 'Onderwerp',
    plural: 'onderwerpen',
    pluralAlt: 'Onderwerpen'
  }
}

exports.entity = entity

exports.getHeadline = () => ({
  meta: entity.labels.singularAlt,
  link: {
    text: `alle ${entity.labels.plural}`,
    url: entity.urls.index
  }
})

exports.create = async (graphql, createPage, wrapper) => {
  createPage({
    path: entity.urls.index,
    component: require.resolve('./page.jsx')
  })

  const result = await wrapper(
    graphql(`
      {
        allPrismicTopic {
          edges {
            node {
              uid
              data {
                name
              }
            }
          }
        }
      }
    `)
  )

  result.data.allPrismicTopic.edges.forEach(edge => {
    createPage({
      path: entity.urls.link(edge.node),
      component: require.resolve('./template.jsx'),
      context: {
        uid: edge.node.uid,
        topic: edge.node.data.name
      }
    })
  })
}
