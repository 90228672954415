// https://www.gatsbyjs.org/docs/add-seo-component/

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'
import Schema from './Schema'
import Meta from './Meta'
import { trimTrailingSlash } from '../../utils'

const query = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultLogo: logo
        siteUrl
        siteLanguage
        ogLanguage
        twitter
        facebook
      }
    }
  }
`

const SEO = ({ title, desc, logo, pathname, article, node }) => {
  const {
    site: {
      siteMetadata: {
        siteUrl,
        defaultTitle,
        defaultDescription,
        defaultLogo,
        siteLanguage,
        ogLanguage,
        twitter,
        facebook
      }
    }
  } = useStaticQuery(query)

  const path = (pathname || '').replace(/^\/|\/$/g, '')
  const siteUrlTrimmed = trimTrailingSlash(siteUrl)
  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrlTrimmed}/${logo || defaultLogo}`,
    url: `${siteUrlTrimmed}/${path}`
  }

  return (
    <>
      <Meta title={seo.title} lang={siteLanguage} desc={seo.description} image={seo.image} />
      <Schema seo={seo} article={article} node={node} />

      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />

      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  logo: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.shape({})
}

SEO.defaultProps = {
  title: null,
  desc: null,
  logo: null,
  pathname: null,
  article: false,
  node: null
}
