import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from 'gatsby-image/withIEPolyfill'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Topics } from '../..'
import Wrapper from '../../Wrapper'
import Header from '../Header'

const BASE_Z_INDEX = 0

/* Layer -2: Background Image */
const StyledBgImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${BASE_Z_INDEX - 2};
`

/* Layer -1: Styled Overlay */
const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${BASE_Z_INDEX - 1};
  display: ${props => (props.show ? 'block' : 'none')};
  opacity: 0.25;
  background-color: ${props => props.theme.colors.primary};

  ${'' /* background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); */};
`

/* Layer 0: Base level  */
const StyledHero = styled.header`
  position: relative;
  z-index: ${BASE_Z_INDEX};
  background-color: ${props => !props.image && (props.invert ? props.theme.colors.primary : props.theme.colors.light)};

  h1,
  p,
  a {
    color: ${props => (props.invert ? props.theme.colors.white : props.theme.colors.dark)};
  }
`

const StyledWrapper = styled(Wrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => (props.image && props.featured ? 'center' : 'normal')};
  height: ${props => props.height};

  ${props => props.image && props.featured && `height: 100vh;`};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    ${props => props.image && !props.featured && `height: 15rem`};

    ${props =>
    props.image &&
      !props.featured &&
      css(`
        @media (orientation: landscape) {
          height: auto;
        }
      `)};
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    @media (orientation: landscape) {
      ${props => props.image && !props.featured && `max-height: 100vh`};
    }
  }
`

const StyledTitle = styled.h1`
  word-break: break-word;
  font-size: 300%;
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 250%;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 200%;
  }
`

const StyledHeadline = styled.p`
  font-size: 125%;
  margin-bottom: 0;

  a {
    color: inherit;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 150%;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 100%;
  }
`

const StyledAnchor = styled(AnchorLink)`
  @keyframes scrollAnimation {
    0% {
      top: 0;
      opacity: 0.5;
      transform: rotate(90deg) scale(0.9);
    }
    50% {
      top: 1rem;
      opacity: 0.85;
      transform: rotate(90deg) scale(1);
    }
    100% {
      top: 0;
      opacity: 0.5;
      transform: rotate(90deg) scale(0.9);
    }
  }

  position: relative;
  display: block;
  font-size: 300%;
  border-radius: 50px;
  margin-top: -1rem;
  animation: scrollAnimation 3s infinite;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const AuthorImage = styled.img`
  position: absolute;
  border-radius: 500px;
  border: 3px solid ${props => props.theme.colors.light};
  box-shadow: 1px 1px 5px ${props => props.theme.colors.light};
  height: 150px;
  width: 150px;
  right: 150px;
  bottom: -75px;
  opacity: 0.95;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 125px;
    width: 125px;
    right: 75px;
    bottom: -65px;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`

const Hero = ({
  title,
  invert,
  headline,
  topics,
  children,
  image,
  featured,
  author,
  properties: { overlay, height }
}) => (
  <StyledHero invert={invert} image={image.localFile && image.localFile.childImageSharp.fluid}>
    {image.localFile && image.localFile.childImageSharp.fluid && (
      <>
        <StyledBgImage objectFit='cover' objectPosition='50% 50%' fluid={image.localFile.childImageSharp.fluid} />
        <StyledOverlay invert={invert} show={overlay} />
      </>
    )}

    <StyledWrapper height={height} featured={featured} image={image.localFile && image.localFile.childImageSharp.fluid}>
      <Header invert={invert || overlay} />

      {headline.meta && (
        <StyledHeadline className='font-family-alt'>
          {headline.meta}
          {topics && <Topics topics={topics} />}
          {headline.link && (
            <>
              <span> — </span>
              <Link to={headline.link.url}>{headline.link.text}</Link>
            </>
          )}
        </StyledHeadline>
      )}

      {title && <StyledTitle>{title}</StyledTitle>}
      {children}
      {featured && (
        <StyledAnchor className='skip-to-content' href='#reach-skip-nav'>
          &raquo;
        </StyledAnchor>
      )}
      {author.profile_picture && author.profile_picture.url && (
        <AuthorImage src={author.profile_picture.url} alt='Auteur' />
      )}
    </StyledWrapper>
  </StyledHero>
)

Hero.propTypes = {
  title: PropTypes.string,
  invert: PropTypes.bool,
  featured: PropTypes.bool,
  headline: PropTypes.shape({
    meta: PropTypes.string,
    link: PropTypes.shape({})
  }),
  author: PropTypes.shape({
    name: PropTypes.string,
    profile_picture: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  image: PropTypes.shape({
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({})
      }).isRequired
    })
  }),
  properties: PropTypes.shape({
    overlay: PropTypes.bool,
    height: PropTypes.string
  }),
  topics: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
}

Hero.defaultProps = {
  title: null,
  invert: false,
  featured: false,
  headline: {},
  author: {
    name: null,
    profile_picture: {
      url: null
    }
  },
  image: {
    localFile: {
      childImageSharp: {
        fluid: null
      }
    }
  },
  properties: {
    overlay: false
  },
  topics: [],
  children: null
}

export default Hero
