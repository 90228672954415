import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { entity, getHeadline } from '.'
import { AuthorZone, Layout, Listing, Wrapper, SliceZone, SEO, Hero } from '../../components'
import website from '../../../config/website'

const Main = Wrapper.withComponent('main')

const Article = ({ data: { prismicArticle, articles }, location }) => {
  const { data } = prismicArticle

  return (
    <Layout customSEO>
      <SEO
        title={`${data.title.text} | ${website.titleShort}`}
        pathname={location.pathname}
        desc={data.description}
        node={prismicArticle}
        article
      />
      <Hero title={data.title.text} headline={getHeadline(data.date)} topics={prismicArticle.data.topics} />
      <Main id={website.skipNavId} zones>
        {data.author && <AuthorZone {...data.author} />}
        <SliceZone allSlices={data.body} />

        {articles.edges.length > 0 && (
          <Listing
            title={`Andere ${entity.labels.plural}`}
            edges={articles.edges}
            className="zone"
            link={edge => entity.urls.link(edge.node)}
          />
        )}
      </Main>
    </Layout>
  )
}

export default Article

Article.propTypes = {
  data: PropTypes.shape({
    prismicArticle: PropTypes.shape({}).isRequired
  }).isRequired,
  location: PropTypes.shape({}).isRequired
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query ArticleTemplateQuery($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        ...ArticleData
        author {
          ...ArticleAuthor
        }
        body {
          ... on PrismicArticleBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicArticleBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicArticleBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  ...FluidImage
                }
              }
            }
          }
        }
      }
    }
    articles: allPrismicArticle(
      limit: 3
      sort: { fields: [data___date], order: DESC }
      filter: { uid: { ne: $uid }, data: { enabled: { eq: "Yes" } } }
    ) {
      edges {
        ...ArticleEdge
      }
    }
  }
`
