import React, { useCallback } from 'react'
import Helmet from 'react-helmet'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useDispatch, useMappedState } from 'redux-react-hook'
import styled from '@emotion/styled'

import { getIconFromLink, printLink } from './Navigation'
import { utils } from '../../store/constants'

const query = graphql`
  query MobileNavigationQuery {
    pageLinks: allPrismicHeroLinksBodyPageLink {
      edges {
        node {
          primary {
            label
            link
          }
        }
      }
    }
  }
`

const MobileMenu = styled.nav`
  @keyframes slideIn {
    0% {
      margin-left: -100vw;
    }
    100% {
      margin-left: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.menu}) {
    display: none;
  }

  position: relative;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.light};
  transition: opacity 0.5s, visibility 0.35s, height 0.25s;

  html.mobile-menu-open & {
    height: 100vh;
    opacity: 0.975;
    visibility: visible;

    ul {
      li {
        margin-left: -100vw;
        animation: slideIn 0.5s ease forwards;
        animation-delay: 0.2s;

        &:nth-of-type(2) {
          animation-delay: 0.25s;
        }

        &:nth-of-type(3) {
          animation-delay: 0.3s;
        }

        &:nth-of-type(4) {
          animation-delay: 0.35s;
        }

        &:nth-of-type(5) {
          animation-delay: 0.4s;
        }

        @media (orientation: landscape) {
          animation: none;
          margin: 0;
        }
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    color: inherit;
    opacity: 0.75;

    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 100%;
      cursor: pointer;
      color: inherit;
      transition: background 0.2s ease;

      a {
        flex: 1;
        color: inherit;
        padding: 1rem ${props => props.theme.framework.unit.default};
        text-decoration: none;

        svg {
          margin-right: 0.5rem;
        }

        &[aria-current='page'] {
          text-decoration: underline;
        }
      }

      svg {
        color: inherit;

        + a {
          margin-left: 0.5rem;
        }
      }

      &:hover {
        background: ${props => props.theme.colors.grey};
      }
    }

    @media (orientation: landscape) {
      flex-direction: row;
      flex-wrap: wrap;

      li {
        flex-basis: 50%;
      }
    }
  }
`

const CloseButton = styled.small`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem ${props => props.theme.framework.unit.default};
  color: ${props => props.theme.colors.white};
  opacity: 0.75;
  width: 100%;
  text-align: right;
  cursor: pointer;
  font-weight: 300;
`

const MenuLabel = styled.span`
  display: block;
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding: 1rem ${props => props.theme.framework.unit.default};
  width: 100%;
  text-transform: uppercase;
  opacity: 0.75;
  font-weight: 300;
`

const NavigationMobile = () => {
  const { pageLinks } = useStaticQuery(query)
  const dispatch = useDispatch()
  const toggleMenu = useCallback(() => dispatch({ type: utils.TOGGLE_MENU }), [dispatch])
  const isMenuOpen = useMappedState(useCallback(state => state.utils.menu.opened, []))
  const menuStatusMobile = isMenuOpen ? 'mobile-menu-open' : 'mobile-menu-closed'

  return (
    <>
      <Helmet htmlAttributes={{ class: menuStatusMobile }} />
      <MobileMenu className="main-menu-mobile">
        <MenuLabel className="font-family-alt">Menu</MenuLabel>
        <CloseButton className="font-family-alt" onClick={toggleMenu}>
          Sluiten
        </CloseButton>

        <ul>
          <li className="font-family-alt">
            <Link to="/" onClick={toggleMenu}>
              {getIconFromLink('/')}
              Thuis
            </Link>
          </li>

          {pageLinks.edges.map(data => printLink(data, toggleMenu))}
        </ul>
      </MobileMenu>
    </>
  )
}

export default NavigationMobile
