import styled from '@emotion/styled'

const BlockQuote = styled.blockquote`
  position: relative;
  max-width: ${props => props.theme.framework.maxWidth};
  padding: 1rem 2rem 1rem 3rem;
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.light};
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;

  > p {
    margin-top: 1rem;
    font-style: italic;
  }

  :before {
    content: '"';
    position: absolute;
    font-size: 300%;
    top: 0;
    left: 1rem;
    padding: 0.5rem;
    opacity: 0.75;
  }
`

export default BlockQuote
