import _ from 'lodash'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Article from '../../entities/article'
import Testimony from '../../entities/testimony'
import { Carousel } from '.'

export default () => (
  <StaticQuery
    query={graphql`
      query NewCarousel {
        articles: allPrismicArticle(
          limit: 50
          sort: { fields: first_publication_date, order: DESC }
          filter: { data: { enabled: { eq: "Yes" } } }
        ) {
          ...Articles
        }
        testimonies: allPrismicTestimony(
          limit: 50
          sort: { fields: first_publication_date, order: DESC }
          filter: { data: { enabled: { eq: "Yes" } } }
        ) {
          ...Testimonies
        }
      }
    `}
    render={({ articles, testimonies }) => {
      const carouselData = [...articles.edges, ...testimonies.edges]
        .map(edge => edge.node)
        .map(node => {
          const { date, external } = node.data
          const isArticle = node.type === 'article'
          const title = isArticle ? Article.entity.labels.singularAlt : Testimony.entity.labels.singularAlt
          const extra = node.uid && external === 'Yes' ? 'Extern' : null

          return {
            title: `${title}${date ? ` (${date}) ` : ''}${extra ? ` - ${extra}` : ''}`,
            link: isArticle ? Article.entity.urls.link(node) : Testimony.entity.urls.link(node),
            text: node.data.title.text,
            img: _.get(node.data.author, 'document[0].data.profile_picture.url')
          }
        })

      return <Carousel data={carouselData} />
    }}
  />
)
