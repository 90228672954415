import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Quote from './Quote'

const StyledNode = styled.div``

const Node = ({ data }) => (
  <StyledNode className="zone-slice">
    <div dangerouslySetInnerHTML={{ __html: data.title.html + data.content.html }} />

    {data.bible_verses.length > 0 && data.bible_verses.map((verse, i) => <Quote key={i} data={verse} />)}
  </StyledNode>
)

export default Node

Node.propTypes = {
  data: PropTypes.shape({}).isRequired
}
