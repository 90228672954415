import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getHeadline } from '.'
import Article from '../article'
import Testimony from '../testimony'
import { SliceZone, Layout, Listing, Wrapper, SEO, Hero } from '../../components'
import website from '../../../config/website'

const Main = Wrapper.withComponent('main')

const Author = ({ data: { author, testimony, articles }, location }) => (
  // <Layout customSEO>
  <Layout>
    {/* <SEO title={`${author.data.name} | ${website.titleShort}`} pathname={location.pathname} /> */}
    <Hero title={author.data.name} headline={getHeadline()} author={author.data} />
    <Main id={website.skipNavId} zones>
      <Listing
        title={`Mijn ${articles.totalCount === 1 ? Article.entity.labels.singular : Article.entity.labels.plural} (${
          articles.totalCount
        })`}
        edges={articles.edges}
        link={edge => Article.entity.urls.link(edge.node)}
        className="zone"
      />

      {testimony && testimony.data.body && (
        <SliceZone
          title={`Mijn ${Testimony.entity.labels.singular}: ${testimony.data.title.text}`}
          allSlices={testimony.data.body}
        />
      )}
    </Main>
  </Layout>
)

export default Author

Author.propTypes = {
  pageContext: PropTypes.shape({
    uid: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    author: PropTypes.shape({
      data: PropTypes.shape({})
    }).isRequired,
    articles: PropTypes.shape({
      edges: PropTypes.array,
      totalCount: PropTypes.number.isRequired
    }),
    testimony: PropTypes.shape({
      data: PropTypes.shape({
        body: PropTypes.arrayOf(PropTypes.shape({})).isRequired
      }).isRequired
    })
  }),
  location: PropTypes.shape({}).isRequired
}

Author.defaultProps = {
  data: {
    articles: {
      edges: [],
      totalCount: 0
    },
    testimony: {
      data: {
        body: []
      }
    }
  }
}

export const pageQuery = graphql`
  query AuthorTemplateQuery($uid: String!) {
    author: prismicAuthor(uid: { eq: $uid }) {
      ...Author
    }

    articles: allPrismicArticle(filter: { data: { author: { uid: { eq: $uid } }, enabled: { eq: "Yes" } } }) {
      ...Articles
    }

    testimony: prismicTestimony(data: { author: { uid: { eq: $uid } } }) {
      ...Testimony
    }
  }
`
