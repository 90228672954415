import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { IoIosGlobe } from 'react-icons/io'
import { entity, getHeadline } from '.'
import { entity as authorEntity } from '../author'
import { AuthorZone, Layout, Listing, Wrapper, SliceZone, SEO, Hero } from '../../components'
import website from '../../../config/website'

const Main = Wrapper.withComponent('main')

const Testimony = ({ data: { prismicTestimony, testimonies }, location }) => {
  const { data } = prismicTestimony

  return (
    <Layout customSEO>
      <SEO title={`${data.title.text} | ${website.titleShort}`} pathname={location.pathname} node={prismicTestimony} />
      <Hero title={data.title.text} headline={getHeadline(data.date)} />
      <Main id={website.skipNavId} zones>
        {data.author && <AuthorZone {...data.author} />}
        <SliceZone allSlices={data.body} />

        {testimonies.edges.length > 0 && (
          <Listing
            className="zone"
            title={`Andere ${entity.labels.plural}`}
            edges={testimonies.edges}
            link={edge => entity.urls.link(edge.node)}
            date={edge => edge.node.data.date || 'Geen datum'}
            extra={edge =>
              edge.node.data.external === 'Yes' ? (
                <span>
                  <IoIosGlobe /> Extern
                </span>
              ) : edge.node.data.author ? (
                <Link to={authorEntity.urls.link(edge.node.data.author)}>
                  {edge.node.data.author.document[0].data.name}
                </Link>
              ) : null
            }
          />
        )}
      </Main>
    </Layout>
  )
}

export default Testimony

Testimony.propTypes = {
  data: PropTypes.shape({
    prismicTestimony: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.shape({}).isRequired
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query TestimonyTemplateQuery($uid: String!) {
    prismicTestimony(uid: { eq: $uid }) {
      ...Testimony
    }
    testimonies: allPrismicTestimony(
      limit: 2
      sort: { fields: last_publication_date, order: DESC }
      filter: { uid: { ne: $uid }, data: { enabled: { eq: "Yes" } } }
    ) {
      ...Testimonies
    }
  }
`
